<template>
  <v-container class="px-5">
    <v-alert type="warning" outlined dismissible v-if="HasProgressCheck.Status">
      You have a Progress Check scheduled starting on {{ HasProgressCheck.DateText }}.
    </v-alert>
    <p class="text-h4 titulo-card">Dashboard</p>
    <v-row class="my-4">
      <v-col>
        <v-card elevation="4" class="px-4">
          <v-row>
            <v-col cols="9" class="pb-0">
              <p class="text-h6 mb-1">School</p>
              <p class="text-h5 mb-1">{{ School }}</p>
            </v-col>
            <v-col class="my-1 pa-0 text-center" cols="3">
              <img src="../../../assets/admin/school.png" class="imagen-info" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="my-5" v-if="classroomsEnabled">
      <v-col>
        <v-card elevation="2" class="px-4" color="cyan darken-1" flat dark>
          <v-row>
            <v-col cols="8">
              <p class="text-h3 text-center">{{ Classroom }}</p>
              <p class="text-h5 text-center">Classrooms</p>
            </v-col>
            <v-col class="pa-2 text-center my-auto py-auto" cols="4">
              <img src="../../../assets/admin/clase2.png" class="imagen-info" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="2" class="px-4" color="green darken-1" flat dark>
          <v-row>
            <v-col cols="8">
              <p class="text-h3 text-center">{{ Groups }}</p>
              <p class="text-h5 text-center">Groups</p>
            </v-col>
            <v-col class="pa-2 text-center my-auto py-auto" cols="4">
              <img src="../../../assets/admin/groups.png" class="imagen-info" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="2" class="px-4" color="amber darken-3" flat dark>
          <v-row>
            <v-col cols="8">
              <p class="text-h3 text-center">{{ Students }}</p>
              <p class="text-h5 text-center">Students</p>
            </v-col>
            <v-col class="pa-2 text-center my-auto py-auto" cols="4">
              <img
                src="../../../assets/admin/students.png"
                class="imagen-info"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!--<v-card elevation="2" v-if="IsRegister !='true'">-->
    <v-card elevation="2" v-if="classroomsEnabled">
      <v-toolbar dense>
        <v-toolbar-title>Classrooms</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="cyan darken-1" dark to="/schoolTeacher/classroom">
          Go to classrooms
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import { auth, db } from "../../../plugins/firebase";
import firebase from "firebase";
import Pie from "../../../components/PieChart";
export default {
  components: {
    Pie,
  },
  data() {
    return {
      School: "",
      DateExpiration: "",
      Classroom: 0,
      Groups: 0,
      Students: 0,
      pieChartData: {},
      pieChartOptions: {
        responsive: true,
      },
      Mision1: [],
      Mision2: [],
      Mision3: [],
      IsRegister:false,
      classroomsEnabled: false,
      HasProgressCheck:{
        Status: false,
        DateText: ""
      }
    };
  },
  created() {},
  computed: {
    ...mapState('userTeacher', ['userTeacher'])
  },
  watch: {
    userTeacher: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.GetDataTeacher();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async GetDataTeacher() {
      // let iud = this.$store.state.user.uid;
      // let info = await db.collection("SchoolTeacher").doc(iud).get();
      
      if (this.userTeacher && Object.keys(this.userTeacher).length) {
        if (this.userTeacher.FirstTime) {
          this.$router.push({ path: "schoolTeacher/classroom" });
        }
        console.log(this.userTeacher);
        // verificar si la propiedad Test existe en el objeto userTeacher
        if (Object.prototype.hasOwnProperty.call(this.userTeacher, 'Tests')) {
          let IdDate = Object.keys(this.userTeacher.Tests)
          for (const element of IdDate) {
            // element esta en string mm-dd-yyyy , deseo verificar si esta en el rago de 5 dias antes de la fecha actual
            let date = new Date(element);
            let options = { month: "long", day: "numeric" };
            let date2 = date.toLocaleDateString("en-US", options);
            let dateNow = new Date();
            let dateNow5 = new Date();
            dateNow5.setDate(dateNow5.getDate() + 5);
            if (dateNow5 >= date && date >= dateNow) {
              this.HasProgressCheck.Status = true;
              this.HasProgressCheck.DateText = date2;
            } else {
              this.HasProgressCheck.Status = false;
            }
          }
        }

        this.School = this.userTeacher.School;
        let TempDateExpiration = new Date(this.userTeacher.LicenseExpirationDate);
        var options = { year: "numeric", month: "long", day: "numeric" };
        let date = TempDateExpiration.toLocaleDateString("en-US", options);
        this.DateExpiration = date;
        
        if (this.IsRegister)
          this.classroomsEnabled=Object.prototype.hasOwnProperty.call(this.userTeacher, 'ClassroomsEnabled')?this.userTeacher.ClassroomsEnabled:false;
        else
          this.classroomsEnabled=Object.prototype.hasOwnProperty.call(this.userTeacher, 'ClassroomsEnabled')?this.userTeacher.ClassroomsEnabled:true;
        let classrooms = this.userTeacher.Classrooms;
        this.Classroom = 0;
        this.Groups = 0;
        this.Students = 0;
        if (classrooms != undefined) {
          let ids = Object.keys(classrooms);
          this.Classroom = ids.length;

          ids.forEach((element) => {
            this.Groups += classrooms[element].CountGroups;
            this.Students += classrooms[element].CountStudents;
            //newGroup.push({ id: element, name: preGroups[element].name, Students: [] })
          });
        }
      } else {
        console.log('userTeacher aún no está disponible');
      }
    },
    // async convertirDatos(){
    //   // SchoolsData/RegionSchoolD/Countries/2r2JWPAq8NRItilttjH6/States/3udFo7nWv6vQLZYGI0yp/SchoolDistricts/DuomTO0ODAzA5iDxfOyd/Schools/yIo9kpGArJSTVxlWddTq
      
    //   let datos = await db.doc('/SchoolsData/RegionSchoolD/Countries/2r2JWPAq8NRItilttjH6/States/TpFpRZodvG40D5kmvE5z/SchoolDistricts/RlvN3BbwaxAQF1jBUqsv/Schools/PQhU2yt28p2wdO152lLZ').get();
      
    //   let teachers = Object.keys(datos.data().SchoolTeachers);
    //   for (const idteacher of teachers) {
        
    //     let TeacherData = await db.collection("SchoolTeacher").doc(idteacher).get();
    //     let ids = Object.keys(TeacherData.data().Classrooms);
    //     if (ids.length > 0) {
    //       console.log(idteacher);
    //       for (const idClassroom of ids) {
    //         if (TeacherData.data().Classrooms[idClassroom].NumberStudents > 0) {
    //           let RutaTeacher = db.collection("SchoolTeacher").doc(idteacher).collection("Classrooms").doc(idClassroom)
    //           let StudentsData = await RutaTeacher.get();
    //           let idsStudents = Object.keys(StudentsData.data().MainGroup);
    //           for (const idStudent of idsStudents) {
    //             console.log(RutaTeacher.path);
    //             db.collection("Students").doc(idStudent).update({ ClassroomPath: RutaTeacher.path });
                
    //           }
              
    //         }
    //       }
    //     }

        
    //   }
    // }
    // BuscarStudentID(){
    //   db.collection("Students").where("StudentId", "==", "").get()
    //     .then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             // doc.data() is never undefined for query doc snapshots
    //             if (doc.data().Email != "") {
    //               console.log(doc.id, " => ", doc.data());
                  
    //             }
    //         });
    //     })
    //     .catch((error) => {
    //         console.log("Error getting documents: ", error);
    //     });
    // }
  },
  mounted() {
    this.GetDataTeacher();
    this.IsRegister = localStorage.getItem('register');
    
    //
    // this.convertirDatos();
    // this.BuscarStudentID();
  },
};
</script>
<style>
.school-icon {
  background-color: #1f6f1f;
}
.end-date-icon {
  background-color: #da4545;
}
.icons-info {
  font-size: 100px !important;
}
.imagen-info {
  width: 100px;
}
@media only screen and (max-width: 1200px) {
  .icons-info {
    font-size: 80px !important;
  }
  .imagen-info {
    width: 80px;
  }
}
@media only screen and (max-width: 992px) {
  .icons-info {
    font-size: 65px !important;
  }
  .imagen-info {
    width: 65px;
  }
}
@media only screen and (max-width: 768px) {
  .icons-info {
    font-size: 50px !important;
  }
  .imagen-info {
    width: 50px;
  }
}
</style>